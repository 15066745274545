<template>
    <div class="flex">
        <div class="img_list" v-for="(item,idx) in fileList" :key="idx">
            <img :src="img" alt="">
            <div class="masking">
                <i @click="del(idx)" class="iconfont el-icon-delete"></i>
            </div>
        </div>
        <div style="position:relative;">
            <div v-if="fileList.length>=1" class="uploadMask"></div>
            <el-upload class="upload-demo" 
                :show-file-list="false" 
                :limit="1" 
                action="xx" 
                list-type="text" 
                :accept="uploadAccept" 
                :http-request="uploadImg" 
                :before-upload="beforeUpload" 
                :on-remove="removeImg" 
                :file-list="fileList">
                <i v-if="fileList.length<1" style="font-size:14px;color:#016FFF;" class="el-icon-plus"></i>
                <span v-if="fileList.length<1" style="font-size:14px;color:#016FFF;">点击上传</span>
                <span class="fileLength">（{{fileList.length}}）</span>
            </el-upload>
            
            <div class="tip">只能上传CAD支持文件，检验后缀名dwg格式</div>
        </div>
        
        <!-- <div class="fileLength">（{{fileList.length}}）</div> -->
    </div>
</template>
<script>
    import { IMG_URL_PRE } from '@/config'
    import { uploadFile } from '@/service/common';
    export default {
        model: {
            prop: 'imgs',
            event: 'change',
        },
        props: ['imgs','prefix'],
        data() {
            return {
                imgUrl: IMG_URL_PRE,
                img:require('@/assets/images/desgin/icon_cad.png'),
                uploadAccept: '*/dwg',
                fileList:[]
            }
        },
        created() {
            if(this.imgs && this.imgs.length>0){
                for(let img of this.imgs){
                    if (img.indexOf(this.imgUrl)==-1) {
                        this.fileList.push({url:this.imgUrl+img});
                    } else {
                        this.fileList.push({url:img});
                    }
                    
                }
            }
            // console.log(this.imgs);
        },
        methods: {
            del(index) {
                this.fileList.splice(index,1);
            },
            onChange(){
                let _imgs=this.fileList.reduce((pre,cur)=>{
                    let url = cur.url.replace(this.imgUrl, "");
                    pre.push(url);
                    return pre;
                },[]);
                this.$emit('change', _imgs)
            },
            beforeUpload(file) {
                // const isLt2M = file.size / 1024 / 1024 < 2;
                // if (!isLt2M) {
                //     this.$message.error('上传图片大小不能超过 2MB!');
                // }
                let testmsg = file.name.substring(file.name.lastIndexOf('.')+1)
                const extension = testmsg === 'dwg';
                if(!extension){
                    this.$message.error('上传文件只能是dwg格式！');
                }
                return extension;
            },
            uploadImg(item) {
                uploadFile(item.file,this.prefix, {
                        // onUploadProgress: event => {
                        //     item.file.percent = (event.loaded / event.total) * 100;
                        //     item.onProgress(item.file);
                        // }
                    }).then(url => {
                    this.fileList.push({
                        status: "success",
                        uid: new Date().getTime(),
                        url: IMG_URL_PRE + url
                    });
                    this.onChange();
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            removeImg(file, fileList){
                this.fileList=fileList;
                this.onChange();
            }
        },
    }
</script>
<style scoped>
    .upload-demo {
        height: 40px;
        line-height: 40px;
        margin-left: 10px;
    }
    .img_list {
        margin: 0 5px;
        position: relative;
        cursor: pointer;
    }
    .img_list:hover .masking {
        display: block;
    }
    .img_list>img {
        width: 62px;
    }
    .fileLength {
        font-size:12px;
        font-weight:400;
        line-height:16px;
        color:rgba(128,128,128,1);
        padding-top: 53px;
        margin-left: 5px;
    }
    .masking {
        width: 100%;
        height: 100%;
        text-align: center;
        line-height: 71px;
        background: rgba(0, 0, 0, 0.4);
        position: absolute;
        top: 0;left: 0;
        font-size: 14px;
        color: #fff;
        display: none;
    }
    .tip {
        font-size: 12px;
        color: #808080;
        margin-left: 10px;
    }
    .uploadMask {
        width: 80px;
        height: 50px;
        position: absolute;
        top: -10px;
        left: 0;
        /* background: red; */
        z-index: 1;
    }
</style>